var $ = require('jquery');
require('owl.carousel');

var defaultOwlCarouselConfig = {
    loop: true,
    responsiveClass: false,
    autoplay: true,
    items: 1
};

// $('.owl-carousel').each(function() {
//     $(this).owlCarousel($.extend(
//         defaultOwlCarouselConfig, 
//         $(this).data('config')
//     ));
// });


var $primarySlider = $('#primary-slider');

if($primarySlider.length > 0) {
    $primarySlider.owlCarousel({
        loop: true,
        items: 1,
        autoplay: true,
        nav: false,
        dots: true,
		autoHeight: true
    });
}

var $brandsSlider = $('#brands-slider');

if($brandsSlider.length > 0) {
    $brandsSlider.owlCarousel({
        loop: true,
        items: 5,
        autoplay: true,
        nav: true,
        dots: false,
        margin: 90,
        navText:["", ""],
        responsive: {
            0: {
                items: 1
            },

            360: {
                items: 2
            },

            540: {
                items: 3
            },

            768: {
                items: 4
            },

            1200: {
                items: 5
            }
        }
    });
}
