var $ = require('jquery');
var Sharer = require('sharer.npm.js');
var Modal = require('./modal');

$(document).ready(function() {

    // update product quantity
    $('.quantity-button').click(function(e) {
        e.preventDefault();

        var input = $(this).siblings('input');
        var quantity = parseInt(input.val());

        if ($(this).hasClass('dec')) {
            if (quantity > parseInt(input.attr('min'))) {
                input.val(quantity - 1);
            }
        }

        if ($(this).hasClass('inc')) {
            input.val(quantity + 1);
        }
    });


    $('input[name="color_id"]').change(function(e) {
        var colorId = $(this).val();
        $('.size-swatch[data-color-id="' + colorId + '"]').show().siblings().hide();
    });

    $('.size-swatch:visible').find('input:not([disabled])').first().prop('checked', true);

    //$('.wishlist').click(function(e) {
        //e.preventDefault();

        //$.post('/account', {
            //wishlist_add: true,
            //ajax: true,
            //product_id: $('input[name="product_id"]').val(),
            //size_id: $('input[name="size_id"]').val(),
            //color_id: $('input[name="color_id"]').val()
        //}, function(data) {
            //console.log(data)
        //});
    //});

	//$('[data-product-id] .add-to-wishlist').click(function(e) {
		//e.preventDefault();

		//$.post('/account', {
			//wishlist_add: true,
			//ajax: true,
			//product_id: $(this).parents('.product').data('productId')
		//});
	//});

    if($('#share-modal').length) {

        var modal = new Modal($('#share-modal'));

        $('.send-to-friend').click(function(e) {
            e.preventDefault();
            e.stopPropagation();
            modal.open();
        });

        $('#share-modal button').click(function(e) {
            var sharer = new Sharer(this);
            sharer.share();
        });
    }
    
	$('input[name=size_id]').change(function() {
		$('.new-price').html('\u20AC ' + $(this).data('price'));
	})

    $('.cart-content .remove').click(function(e) {
        var form = $(this).parents('form');
        var row = $(this).parents('tr');
        row.find('.quantity input').val(0);
        $('button[name="update"]').trigger('click');
    });
    
    
    if($('#new-address-modal').length) {
           
        var newAddressModal = new Modal($('#new-address-modal'));

        $('#open-new-address-modal').click(function() {
            newAddressModal.open();
        });
    }

    if($('#reset-password').length) {
        var resetPasswordModal = new Modal($('#reset-password'));
        $('#open-reset-password').click(function() {
            resetPasswordModal.open();
        });
    }

	if($('#size-table-modal').length) {
		var sizeModal = new Modal($('#size-table-modal'));
		$('.sizing-table').click(function() {
			sizeModal.open();
		});
	}
});
