window.jQuery = $ = require('jquery');
require('./components/sliders');
//require('./components/lazyload');
require('./components/menu');
require('./components/cloudzoom');
require('./components/product');
require('./lib/jquery-validate');
require('./lib/jquery-metadata');
var googleAnalytics = require('./lib/google-analytics');


'use strict';

$(document).ready(function() {
    $(".validate").each(function() {
        var valid = $(this).validate({
            ignore: ".validate :not(:visible)",
            //ignore: [],
            showErrors: false,
            errorClass: 'error'
        });
        $.metadata.setType("attr", "data-validate");
    });

    $('form.search').submit(function(e) {
        e.stopPropagation();
        e.preventDefault();

        var query = $(this).find('input').val();

        if ($(this).hasClass('open') && query.length > 0) {
            window.location = '/search/' + query;
            return;
        }

        $(this).addClass('open').find('input').focus();
        //$(this).addClass('open').find('input').focus().one('focusout', function() {
            //$('form.search').removeClass('open');
        //});
    });
    
	$("input.autosubmit").change(function(e) {
		//console.log('autosubmit');
        //console.log($(this).parents('form').serialize());
        //e.preventDefault();
        $(this).parents('form').validate().cancelSubmit = true;
        $(this).parents('form').submit();
	});
	
	$("form.autosubmit").change(function() {
		$(this).submit();
	});
	

    $("input.autosubmit").change(function() {
        var id = $(this).attr("id");
        var form = $(this).parents('form');

        form.validate().cancelSubmit = true;
        form.submit();
    });

    $("a.show_div").click(function() {
        var id = $(this).attr("id");
        $("div#" + id + "_div").toggle();
    });

    $("#friend").click(function() {
        $("#friend-modal").toggle();
    });

    $("select#order_select").change(function() {
        $("div.products").hide();
        $("div#products_" + $(this).val()).show();
    });

	$('.mobile.product').click(function() {	
		$(this).addClass('focus').siblings('.product').removeClass('focus');
	});

	$('.mobile.product a').click(function(e) {
		if (!$(this).parents('.product').hasClass('focus')) {
			e.preventDefault();
		}
	});
});
