/**
 * @preserve
 * Sharer.js
 *
 * @description Create your own social share buttons
 * @version 0.2.12
 * @author Ellison Leao <ellisonleao@gmail.com>
 * @license GPLv3
 *
 */

/**
 * @constructor
 */
var Sharer = function(elem) {
    this.elem = elem;
};

Sharer.prototype = {
    /**
     *  @function getValue
     *  @description Helper to get the attribute of a DOM element
     *  @param {String} attr DOM element attribute
     *  @returns {String|Empty} returns the attr value or empty string
     */
    getValue: function(attr) {
        var val = this.elem.getAttribute('data-' + attr);
        return (val === undefined || val === null) ? false : val;
    },

    /**
     * @event share
     * @description Main share event. Will pop a window or redirect to a link
     * based on the data-sharer attribute.
     */
    share: function() {
        var sharer = this.getValue('sharer').toLowerCase(),
            sharers = {
                facebook: {
                    shareUrl: 'https://www.facebook.com/sharer/sharer.php',
                    params: {u: this.getValue('url')}
                },
                googleplus: {
                    shareUrl: 'https://plus.google.com/share',
                    params: {url: this.getValue('url')}
                },
                linkedin: {
                    shareUrl: 'https://www.linkedin.com/shareArticle',
                    params: {
                        url: this.getValue('url'),
                        mini: true
                    }
                },
                twitter: {
                    shareUrl: 'https://twitter.com/intent/tweet/',
                    params: {
                        text: this.getValue('title'),
                        url: this.getValue('url'),
                        hashtags: this.getValue('hashtags'),
                        via: this.getValue('via')
                    }
                },
                email: {
                    shareUrl: 'mailto:' + this.getValue('to'),
                    params: {
                        subject: this.getValue('subject'),
                        body: this.getValue('title') + '\n' + this.getValue('url')
                    },
                    isLink: true
                },
                whatsapp: {
                    shareUrl: 'whatsapp://send',
                    params: {
                        text: this.getValue('title') + ' ' + this.getValue('url')
                    },
                    isLink: true
                },
                telegram: {
                    shareUrl: 'tg://msg_url',
                    params: {
                        text: this.getValue('title') + ' ' + this.getValue('url')
                    },
                    isLink: true
                },
                viber: {
                    shareUrl: 'viber://forward',
                    params: {
                        text: this.getValue('title') + ' ' + this.getValue('url')
                    },
                    isLink: true
                },
                line: {
                    shareUrl: 'http://line.me/R/msg/text/?' + encodeURIComponent(this.getValue('title') + ' ' + this.getValue('url')),
                    isLink: true
                },
                pinterest: {
                    shareUrl: 'https://www.pinterest.com/pin/create/button/',
                    params: {url: this.getValue('url')}
                },
                tumblr: {
                    shareUrl: 'http://tumblr.com/widgets/share/tool',
                    params: {
                        canonicalUrl: this.getValue('url'),
                        content: this.getValue('url'),
                        posttype: 'link',
                        title: this.getValue('title'),
                        caption: this.getValue('caption'),
                        tags: this.getValue('tags')
                    }
                },
                hackernews: {
                    shareUrl: 'https://news.ycombinator.com/submitlink',
                    params: {
                        u: this.getValue('url'),
                        t: this.getValue('title')
                    }
                },
                reddit: {
                    shareUrl: 'https://www.reddit.com/submit',
                    params: {'url': this.getValue('url')}
                },
                vk: {
                    shareUrl: 'http://vk.com/share.php',
                    params: {
                        url: this.getValue('url'),
                        title: this.getValue('title'),
                        description: this.getValue('caption'),
                        image: this.getValue('image')
                    }
                },
                xing: {
                    shareUrl: 'https://www.xing.com/app/user',
                    params: {
                        'op': 'share',
                        'url': this.getValue('url'),
                        'title': this.getValue('title')
                    }
                },
                buffer: {
                    shareUrl: 'https://buffer.com/add',
                    params: {
                        url: this.getValue('url'),
                        title: this.getValue('title'),
                        via: this.getValue('via'),
                        picture: this.getValue('picture')
                    }
                },
                instapaper: {
                    shareUrl: 'http://www.instapaper.com/edit',
                    params: {
                        url: this.getValue('url'),
                        title: this.getValue('title'),
                        description: this.getValue('description')
                    }
                },
                pocket: {
                    shareUrl: 'https://getpocket.com/save',
                    params: {
                        url: this.getValue('url')
                    }
                },
                digg: {
                    shareUrl: 'http://www.digg.com/submit',
                    params: {
                        url: this.getValue('url')
                    }
                },
                stumbleupon: {
                    shareUrl: 'http://www.stumbleupon.com/submit',
                    params: {
                        url: this.getValue('url'),
                        title: this.getValue('title')
                    }
                },
                flipboard: {
                    shareUrl: 'https://share.flipboard.com/bookmarklet/popout',
                    params: {
                        v: 2,
                        title: this.getValue('title'),
                        url: this.getValue('url'),
                        t: Date.now()
                    }
                },
                weibo: {
                    shareUrl: 'http://service.weibo.com/share/share.php',
                    params: {
                        url: this.getValue('url'),
                        title: this.getValue('title'),
                        language: 'zh_cn'
                    }
                },
                renren: {
                    shareUrl: 'http://share.renren.com/share/buttonshare',
                    params: {
                        link: this.getValue('url')
                    }
                },
                myspace: {
                    shareUrl: 'https://myspace.com/post',
                    params: {
                        u: this.getValue('url'),
                        t: this.getValue('title'),
                        c: this.getValue('description')
                    }
                },
                blogger: {
                    shareUrl: 'https://www.blogger.com/blog-this.g',
                    params: {
                        u: this.getValue('url'),
                        n: this.getValue('title'),
                        t: this.getValue('description')
                    }
                },
                baidu: {
                    shareUrl: 'http://cang.baidu.com/do/add',
                    params: {
                        it: this.getValue('title'),
                        iu: this.getValue('url')
                    }
                },
                hatenabookmark: {
                    shareUrl: 'http://b.hatena.ne.jp/entry/' + this.getValue('url'),
                }
            },
            s = sharers[sharer];

        // custom popups sizes
        if (s) {
            s.width = this.getValue('width');
            s.height = this.getValue('height');
        }
        return s !== undefined ? this.urlSharer(s) : false;
    },
    /**
     * @event urlSharer
     * @param {Object} sharer
     */
    urlSharer: function(sharer) {
        var p = sharer.params || {},
            keys = Object.keys(p),
            i,
            str = keys.length > 0 ? '?' : '';
        for (i = 0; i < keys.length; i++) {
            if (str !== '?') {
                str += '&';
            }
            if (p[keys[i]]) {
                str += keys[i] + '=' + encodeURIComponent(p[keys[i]]);
            }
        }
        sharer.shareUrl += str;

        if (!sharer.isLink) {
            var popWidth = sharer.width || 600,
                popHeight = sharer.height || 480,
                left = window.innerWidth / 2 - popWidth / 2 + window.screenX,
                top = window.innerHeight / 2 - popHeight / 2 + window.screenY,
                popParams = 'scrollbars=no, width=' + popWidth + ', height=' + popHeight + ', top=' + top + ', left=' + left,
                newWindow = window.open(sharer.shareUrl, '', popParams);

            if (window.focus) {
                newWindow.focus();
            }
        } else {
            window.location.href = sharer.shareUrl;
        }
    }
};

module.exports = Sharer;
