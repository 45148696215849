var $ = require('jquery');

function Modal($el) {

    this.$el = $el;
    this.$el.find('.fa-times').click(this.close.bind(this));

    $(document).click(function(e) {
        if ($(e.target).parents(this.$el).length === 0) {
            this.close();
        }
    }.bind(this));

    $(window).on('keyup', function(e) {
        if(e.keyCode === 27) {
            this.close();
        }
    }.bind(this));
}

Modal.prototype.close = function() {
    if(this.$el.is(':visible')) {
        $('body').removeClass('not-clickable');
        this.$el.hide();
    }
};

Modal.prototype.open = function() {
    $('body').addClass('not-clickable');
    this.$el.show();
};

module.exports = Modal;
